import { PropsWithChildren, ReactNode, createElement } from "react";
import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { Link } from "@ludens-reklame/rubics-v3-sdk/link/types";
import { Page } from "@ludens-reklame/rubics-v3-sdk/page/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { useClassList } from "../../hooks/useClassList.js";
import { RichText } from "../richText/RichText.js";
import { arrow } from "../../icons/svg.js";
import { LinkList } from "../linkList/linkList.js";

type Heading = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export type CardVariant = "a" | "b";

interface CardProps extends PropsWithChildren {
  variant?: CardVariant;
  image?: Image;
  subtitle?: ReactNode;
  linkList?: Link[];
  title?: {
    type: Heading;
    text: string;
  };
  link?: Link;
  className?: string;
  btnText?: string;
}

export const Card: React.FC<CardProps> = ({
  variant = "a",
  image,
  subtitle,
  title,
  link,
  className,
  linkList = [],
  children,
  btnText,
}) => {
  const cardTextClassList = useClassList("card-text", {
    class: "center",
    active: !children || variant === "b",
  });

  const imageClassList = useClassList("card-image", {
    class: "poster",
    active: variant === "b",
  });

  const imageUrl = image
    ? transformImageUrl(
        image,
        variant === "b" ? { height: 1200 } : { width: 1200 }
      )
    : undefined;

  return createElement(
    variant === "b" && link ? "a" : "div",
    {
      className: className ? `card ${className}` : "card",
      href: variant === "b" && link ? link.url : undefined,
    },
    <div className="card-content">
      {image && imageUrl && (
        <div className={imageClassList.classNames}>
          <img src={imageUrl} alt={image.altText} loading="lazy" />
        </div>
      )}
      <div className={cardTextClassList.classNames}>
        {linkList.length > 0 && (
          <div className="b3 subtitle">
            <LinkList>
              {linkList.map((l) => (
                <a key={l.url} href={l.url} aria-label={l.ariaLabel}>
                  {l.title}
                </a>
              ))}
            </LinkList>
          </div>
        )}
        {title && (
          <>
            {createElement(
              title.type,
              { className: "card-title h5" },
              title.text
            )}
            {createElement(
              title.type,
              { className: "card-title h3" },
              title.text
            )}
          </>
        )}
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {variant !== "b" && children}
        {link && variant !== "b" && (
          <a
            className="button secondary"
            href={link.url}
            aria-label={link.ariaLabel}
          >
            {btnText ?? "Les mer"} {arrow}
          </a>
        )}
      </div>
    </div>
  );
};

export const PageCard: React.FC<
  {
    page: Page;
    titleType: Heading;
  } & Pick<CardProps, "variant" | "className">
> = ({ page, titleType, ...rest }) => {
  const lastParents = page.parents.slice(-3);

  return (
    <Card
      image={page.image}
      title={{ type: titleType, text: page.title }}
      link={{
        url: page.url,
        ariaLabel: `Les mer om ${page.title}`,
        external: false,
      }}
      linkList={lastParents.map((p) => ({
        url: p.url,
        title: p.title,
        ariaLabel: `Les mer om ${p.title}`,
        external: false,
      }))}
      {...rest}
    >
      {page.excerpt && <RichText html={page.excerpt} />}
    </Card>
  );
};
